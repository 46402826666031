/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {StoricoRisultatiMultiplayerWidget} from '../../../_metronic/partials/widgets/customs/StoricoRisultatiMultiplayerWidget'

export function Storico_risultati_multiplayer() {
  return (
    <>	
      <div className='col-xl-12'>
        <StoricoRisultatiMultiplayerWidget className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </>
  )
}
