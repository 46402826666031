/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useMemo, useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {KTSVG, useDebounce} from '../../../helpers'
import {listResultMultiplayer} from '../../../../api/resultMultiplayer'
import {listSimulation} from '../../../../api/simulationApi'
import {listSessionVR} from '../../../../api/sessionVRApi'
import Select from 'react-select'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {UsersListPagination} from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import './StoricoRisultatiMultiplayerWidget.css';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

interface MyData {
  Foto_gruppo: Blob
  Simulazione: string
  Data_risultato: string
  ID_Sessione_VR: string
  ID_Risultato_Multiplayer: number
}

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type Props = {
  className: string
}

interface SelectedOption {
  value: string;
  label: string;
}

const StoricoRisultatiMultiplayerWidget: React.FC<Props> = ({className}) => {
  const {currentUser, logout} = useAuth()
  const user = Array.isArray(currentUser) && currentUser.length > 0 ? currentUser[0] : currentUser;

  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(''); 
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'ID_Risultato_Multiplayer', direction: 'desc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;

  const [typeSimulation, setTypeSimulation] = useState<string>('');
  const [typeSessionVR, setTypeSessionVR] = useState<string>(''); 
  const [sessionVRList, setSessionVRList] = useState<any[]>([]);
  const [simulationList, setSimulationList] = useState<any[]>([]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (sortConfig.key === 'ID_Risultato_Multiplayer') {
        const aValue = Number(a.ID_Risultato_Multiplayer) || 0;
        const bValue = Number(b.ID_Risultato_Multiplayer) || 0;
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });
  }, [filteredData, sortConfig]);

  const fetchData = useCallback(async () => {
    try {
      let response = await listResultMultiplayer(user.COD_User);
      for (let i = 0; i < response.length; i++) {
        let Data_risultato_US = dayjs(response[i].Data_risultato);
        response[i].Data_risultato = Data_risultato_US.format('LL');
      }

      if (typeSimulation && typeSimulation !== '') {
        response = response.filter((item: MyData) => String(item.Simulazione) === typeSimulation);
      }

      if (typeSessionVR && typeSessionVR !== '') {
        response = response.filter((item: MyData) => String(item.ID_Sessione_VR) === typeSessionVR);
      }

      setData(response);
    } catch (error) {
      console.error(error);
    }
  }, [typeSimulation, typeSessionVR]);

  useEffect(() => {
    fetchData();
  }, [fetchData, typeSimulation, typeSessionVR]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(item => {
        return Object.values(item).some(val => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearchTerm, data]);

  useEffect(() => {
    const fetchSimulazioni = async () => {
      try {
        const response = await listSimulation();

        // Filtra solo le simulazioni con Tipologia "Multiplayer"
        const filteredSimulazioni = response.filter((simulazione: any) => simulazione.Tipologia === 'Multiplayer');
        
        setSimulationList(filteredSimulazioni);
      } catch (error) {
        console.error('Errore nel recupero delle simulazioni:', error);
      }
    };
    fetchSimulazioni();
  }, []);

  useEffect(() => {
    const fetchSessioniVR = async () => {
      try {
        const response = await listSessionVR(user.COD_User);
        setSessionVRList(response);
      } catch (error) {
        console.error('Errore nel recupero delle sessioni VR:', error);
      }
    };
    fetchSessioniVR();
  }, []);
  
  const changeSort = (key: keyof MyData) => {
    setSortConfig(currentSortConfig => {
      if (currentSortConfig.key === key) {
        return { key, direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  const handleSimulationChange = (selectedOption: SelectedOption | null) => {
    if (selectedOption) {
      setTypeSimulation(selectedOption.value);
    } else {
      setTypeSimulation('');
    }
  };

  const handleSessionVRChange = (selectedOption: SelectedOption | null) => {
    if (selectedOption) {
      setTypeSessionVR(selectedOption.value);
    } else {
      setTypeSessionVR('');
    }
  };

  const handleDownloadFotoGruppo = (fotoGruppo: any, recordId: number) => {
    if (!fotoGruppo) {
      console.error('Foto_gruppo non è disponibile per questo record.');
      return;
    }
  
    // Verifica la struttura di foto_gruppo
    console.log('Tipo di dato fotoGruppo:', typeof fotoGruppo);
    console.log('Contenuto fotoGruppo:', fotoGruppo);
  
    // Se foto_gruppo è un buffer (array di byte), lo convertiamo in un Blob
    if (typeof fotoGruppo === 'object' && fotoGruppo instanceof Uint8Array) {
      console.log('Convertendo Uint8Array in Blob...');
      const blob = new Blob([fotoGruppo], { type: 'image/png' }); // Assumi che sia un'immagine PNG
      const blobUrl = URL.createObjectURL(blob);
      saveAs(blobUrl, `Foto_gruppo_${recordId}.png`);
      return;
    }
  
    // Se foto_gruppo contiene una proprietà "data" che è un array di byte (comune nei buffer)
    if (typeof fotoGruppo === 'object' && Array.isArray(fotoGruppo.data)) {
      console.log('Convertendo Array in Uint8Array e poi in Blob...');
      const byteArray = new Uint8Array(fotoGruppo.data);
      const blob = new Blob([byteArray], { type: 'image/png' }); // Assumi che sia un'immagine PNG
      const blobUrl = URL.createObjectURL(blob);
      saveAs(blobUrl, `Foto_gruppo_${recordId}.png`);
      return;
    }
  
    console.error('Formato di foto_gruppo non supportato o riconosciuto.');
  };
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Barra ricerca */}
      <div className='card-header border-0 pt-6'>
        <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          <button type='button' className='btn btn-light-primary me-3' onClick={exportDataToCSV}>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>
        </div>
      </div>
      {/* end::Barra ricerca */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Storico risultati</span>
        </h3>
        <div className='card-toolbar'>
          <Select
            className='form-select-solid form-select-lg fw-bold select-z-index'
            options={[
              { value: '', label: 'Tutte le sessioni VR' },
              ...sessionVRList.map((session) => ({ value: session.ID_Sessione_VR, label: session.ID_Sessione_VR }))
            ]}
            isSearchable={false}
            onChange={handleSessionVRChange}
            placeholder="Tutte le sessioni VR"
          />
          <Select
            className='form-select-solid form-select-lg fw-bold select-z-index'
            options={[
              { value: '', label: 'Tutte le simulazioni' },
              ...simulationList.map((simulazione) => ({ value: simulazione.Titolo, label: simulazione.Titolo }))
            ]}
            isSearchable={false}
            onChange={handleSimulationChange}
            placeholder= "Tutte le simulazioni"
          />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Simulazione')}>Simulazione</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Data_risultato')}>Data risultato</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('ID_Sessione_VR')}>Sessione VR</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold'>Foto gruppo</th>
                  </tr>
                </thead>
                <tbody>
                {sortedData.slice(start, end).map(item => (
                    <tr key={item.ID_Risultato_Multiplayer}>
                      <td></td>
                      <td className='text-muted fw-semibold'>{item.Simulazione || '-'}</td>
                      <td className='text-muted fw-semibold'>{item.Data_risultato || '-'}</td>
                      <td className='text-muted fw-semibold'>{item.ID_Sessione_VR || '-'}</td>
                      <td className='text-muted fw-semibold'>
                        <button
                          className='btn btn-icon btn-light-primary'
                          onClick={() => handleDownloadFotoGruppo(item.Foto_gruppo, item.ID_Risultato_Multiplayer)}
                        >
                          <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='pagination-z-index'>
              <UsersListPagination
                totalItems={filteredData.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {StoricoRisultatiMultiplayerWidget}